exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-co-to-index-js": () => import("./../../../src/pages/a-co-to/index.js" /* webpackChunkName: "component---src-pages-a-co-to-index-js" */),
  "component---src-pages-home-eco-js": () => import("./../../../src/pages/home/eco.js" /* webpackChunkName: "component---src-pages-home-eco-js" */),
  "component---src-pages-home-fancystuff-js": () => import("./../../../src/pages/home/fancystuff.js" /* webpackChunkName: "component---src-pages-home-fancystuff-js" */),
  "component---src-pages-home-hero-js": () => import("./../../../src/pages/home/hero.js" /* webpackChunkName: "component---src-pages-home-hero-js" */),
  "component---src-pages-home-sets-js": () => import("./../../../src/pages/home/sets.js" /* webpackChunkName: "component---src-pages-home-sets-js" */),
  "component---src-pages-home-testimonials-js": () => import("./../../../src/pages/home/testimonials.js" /* webpackChunkName: "component---src-pages-home-testimonials-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-index-js": () => import("./../../../src/pages/kontakt/index.js" /* webpackChunkName: "component---src-pages-kontakt-index-js" */),
  "component---src-pages-list-js": () => import("./../../../src/pages/list.js" /* webpackChunkName: "component---src-pages-list-js" */),
  "component---src-pages-polityka-prywatnosci-index-js": () => import("./../../../src/pages/polityka-prywatnosci/index.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-index-js" */),
  "component---src-pages-pytania-i-odpowiedzi-index-js": () => import("./../../../src/pages/pytania-i-odpowiedzi/index.js" /* webpackChunkName: "component---src-pages-pytania-i-odpowiedzi-index-js" */),
  "component---src-pages-pytania-i-odpowiedzi-item-js": () => import("./../../../src/pages/pytania-i-odpowiedzi/item.js" /* webpackChunkName: "component---src-pages-pytania-i-odpowiedzi-item-js" */),
  "component---src-pages-regulamin-index-js": () => import("./../../../src/pages/regulamin/index.js" /* webpackChunkName: "component---src-pages-regulamin-index-js" */),
  "component---src-pages-ups-js": () => import("./../../../src/pages/ups.js" /* webpackChunkName: "component---src-pages-ups-js" */),
  "component---src-pages-zestaw-gry-i-puzzle-dzieki-js": () => import("./../../../src/pages/zestaw/gry-i-puzzle/dzieki.js" /* webpackChunkName: "component---src-pages-zestaw-gry-i-puzzle-dzieki-js" */),
  "component---src-pages-zestaw-gry-i-puzzle-index-js": () => import("./../../../src/pages/zestaw/gry-i-puzzle/index.js" /* webpackChunkName: "component---src-pages-zestaw-gry-i-puzzle-index-js" */),
  "component---src-pages-zestaw-gry-i-puzzle-partials-gallery-js": () => import("./../../../src/pages/zestaw/gry-i-puzzle/partials/gallery.js" /* webpackChunkName: "component---src-pages-zestaw-gry-i-puzzle-partials-gallery-js" */),
  "component---src-pages-zestaw-gry-i-puzzle-partials-hero-js": () => import("./../../../src/pages/zestaw/gry-i-puzzle/partials/hero.js" /* webpackChunkName: "component---src-pages-zestaw-gry-i-puzzle-partials-hero-js" */),
  "component---src-pages-zestaw-gry-i-puzzle-partials-how-it-works-js": () => import("./../../../src/pages/zestaw/gry-i-puzzle/partials/how-it-works.js" /* webpackChunkName: "component---src-pages-zestaw-gry-i-puzzle-partials-how-it-works-js" */),
  "component---src-pages-zestaw-gry-i-puzzle-partials-personalization-js": () => import("./../../../src/pages/zestaw/gry-i-puzzle/partials/personalization.js" /* webpackChunkName: "component---src-pages-zestaw-gry-i-puzzle-partials-personalization-js" */),
  "component---src-pages-zestaw-gry-i-puzzle-partials-toys-js": () => import("./../../../src/pages/zestaw/gry-i-puzzle/partials/toys.js" /* webpackChunkName: "component---src-pages-zestaw-gry-i-puzzle-partials-toys-js" */),
  "component---src-pages-zestaw-gry-i-puzzle-zamow-js": () => import("./../../../src/pages/zestaw/gry-i-puzzle/zamow.js" /* webpackChunkName: "component---src-pages-zestaw-gry-i-puzzle-zamow-js" */),
  "component---src-pages-zestaw-na-podroz-dzieki-js": () => import("./../../../src/pages/zestaw/na-podroz/dzieki.js" /* webpackChunkName: "component---src-pages-zestaw-na-podroz-dzieki-js" */),
  "component---src-pages-zestaw-na-podroz-index-js": () => import("./../../../src/pages/zestaw/na-podroz/index.js" /* webpackChunkName: "component---src-pages-zestaw-na-podroz-index-js" */),
  "component---src-pages-zestaw-na-podroz-partials-bag-js": () => import("./../../../src/pages/zestaw/na-podroz/partials/bag.js" /* webpackChunkName: "component---src-pages-zestaw-na-podroz-partials-bag-js" */),
  "component---src-pages-zestaw-na-podroz-partials-extra-filling-js": () => import("./../../../src/pages/zestaw/na-podroz/partials/extra-filling.js" /* webpackChunkName: "component---src-pages-zestaw-na-podroz-partials-extra-filling-js" */),
  "component---src-pages-zestaw-na-podroz-partials-hero-js": () => import("./../../../src/pages/zestaw/na-podroz/partials/hero.js" /* webpackChunkName: "component---src-pages-zestaw-na-podroz-partials-hero-js" */),
  "component---src-pages-zestaw-na-podroz-partials-personalization-js": () => import("./../../../src/pages/zestaw/na-podroz/partials/personalization.js" /* webpackChunkName: "component---src-pages-zestaw-na-podroz-partials-personalization-js" */),
  "component---src-pages-zestaw-na-podroz-partials-toys-js": () => import("./../../../src/pages/zestaw/na-podroz/partials/toys.js" /* webpackChunkName: "component---src-pages-zestaw-na-podroz-partials-toys-js" */),
  "component---src-pages-zestaw-na-podroz-zamow-js": () => import("./../../../src/pages/zestaw/na-podroz/zamow.js" /* webpackChunkName: "component---src-pages-zestaw-na-podroz-zamow-js" */),
  "component---src-pages-zestaw-shared-order-contact-js": () => import("./../../../src/pages/zestaw/shared/order/contact.js" /* webpackChunkName: "component---src-pages-zestaw-shared-order-contact-js" */),
  "component---src-pages-zestaw-shared-order-hero-js": () => import("./../../../src/pages/zestaw/shared/order/hero.js" /* webpackChunkName: "component---src-pages-zestaw-shared-order-hero-js" */),
  "component---src-pages-zestaw-shared-order-receipt-js": () => import("./../../../src/pages/zestaw/shared/order/receipt.js" /* webpackChunkName: "component---src-pages-zestaw-shared-order-receipt-js" */),
  "component---src-pages-zestaw-shared-order-shipping-js": () => import("./../../../src/pages/zestaw/shared/order/shipping.js" /* webpackChunkName: "component---src-pages-zestaw-shared-order-shipping-js" */),
  "component---src-pages-zestaw-shared-order-submit-js": () => import("./../../../src/pages/zestaw/shared/order/submit.js" /* webpackChunkName: "component---src-pages-zestaw-shared-order-submit-js" */),
  "component---src-pages-zestaw-shared-show-delivery-js": () => import("./../../../src/pages/zestaw/shared/show/delivery.js" /* webpackChunkName: "component---src-pages-zestaw-shared-show-delivery-js" */),
  "component---src-pages-zestaw-shared-show-faq-js": () => import("./../../../src/pages/zestaw/shared/show/faq.js" /* webpackChunkName: "component---src-pages-zestaw-shared-show-faq-js" */),
  "component---src-pages-zestaw-shared-show-ordercta-js": () => import("./../../../src/pages/zestaw/shared/show/ordercta.js" /* webpackChunkName: "component---src-pages-zestaw-shared-show-ordercta-js" */),
  "component---src-pages-zestaw-swiateczny-box-kreatywny-dzieki-js": () => import("./../../../src/pages/zestaw/swiateczny-box-kreatywny/dzieki.js" /* webpackChunkName: "component---src-pages-zestaw-swiateczny-box-kreatywny-dzieki-js" */),
  "component---src-pages-zestaw-swiateczny-box-kreatywny-index-js": () => import("./../../../src/pages/zestaw/swiateczny-box-kreatywny/index.js" /* webpackChunkName: "component---src-pages-zestaw-swiateczny-box-kreatywny-index-js" */),
  "component---src-pages-zestaw-swiateczny-box-kreatywny-zamow-js": () => import("./../../../src/pages/zestaw/swiateczny-box-kreatywny/zamow.js" /* webpackChunkName: "component---src-pages-zestaw-swiateczny-box-kreatywny-zamow-js" */),
  "component---src-pages-zestaw-zabawki-i-sensoryka-dzieki-js": () => import("./../../../src/pages/zestaw/zabawki-i-sensoryka/dzieki.js" /* webpackChunkName: "component---src-pages-zestaw-zabawki-i-sensoryka-dzieki-js" */),
  "component---src-pages-zestaw-zabawki-i-sensoryka-index-js": () => import("./../../../src/pages/zestaw/zabawki-i-sensoryka/index.js" /* webpackChunkName: "component---src-pages-zestaw-zabawki-i-sensoryka-index-js" */),
  "component---src-pages-zestaw-zabawki-i-sensoryka-new-js": () => import("./../../../src/pages/zestaw/zabawki-i-sensoryka/new.js" /* webpackChunkName: "component---src-pages-zestaw-zabawki-i-sensoryka-new-js" */),
  "component---src-pages-zestaw-zabawki-i-sensoryka-partials-gallery-js": () => import("./../../../src/pages/zestaw/zabawki-i-sensoryka/partials/gallery.js" /* webpackChunkName: "component---src-pages-zestaw-zabawki-i-sensoryka-partials-gallery-js" */),
  "component---src-pages-zestaw-zabawki-i-sensoryka-partials-hero-js": () => import("./../../../src/pages/zestaw/zabawki-i-sensoryka/partials/hero.js" /* webpackChunkName: "component---src-pages-zestaw-zabawki-i-sensoryka-partials-hero-js" */),
  "component---src-pages-zestaw-zabawki-i-sensoryka-partials-how-it-works-js": () => import("./../../../src/pages/zestaw/zabawki-i-sensoryka/partials/how-it-works.js" /* webpackChunkName: "component---src-pages-zestaw-zabawki-i-sensoryka-partials-how-it-works-js" */),
  "component---src-pages-zestaw-zabawki-i-sensoryka-partials-personalization-js": () => import("./../../../src/pages/zestaw/zabawki-i-sensoryka/partials/personalization.js" /* webpackChunkName: "component---src-pages-zestaw-zabawki-i-sensoryka-partials-personalization-js" */),
  "component---src-pages-zestaw-zabawki-i-sensoryka-partials-toys-js": () => import("./../../../src/pages/zestaw/zabawki-i-sensoryka/partials/toys.js" /* webpackChunkName: "component---src-pages-zestaw-zabawki-i-sensoryka-partials-toys-js" */),
  "component---src-pages-zestaw-zabawki-i-sensoryka-zamow-js": () => import("./../../../src/pages/zestaw/zabawki-i-sensoryka/zamow.js" /* webpackChunkName: "component---src-pages-zestaw-zabawki-i-sensoryka-zamow-js" */)
}

